@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;300;400;500;600;700;800;900&display=swap');

//---- STYLE DE GRAS ----
//Thin 100
.thin{
  font-weight: 100;
}
//ExtraLight 200
.extraLight{
  font-weight: 200;
}
//Light 300
.light{
  font-weight: 300;
}
//Regular 400
.regular{
  font-weight: 400;
}
//Medium 500
.medium{
  font-weight: 500;
}
//SemiBold 600
.semiBold{
  font-weight: 600;
}
//Bold 700
.bold{
  font-weight: 700;
}
//ExtraBold 800
.extraBold{
  font-weight: 800;
}
//Black 900
.black{
  font-weight: 900;
}

//---- TAILLE DE POLICE ----
.font-8 {
  font-family: 'Archivo';
  font-size: 8px!important;
}
.font-9 {
  font-family: 'Archivo';
  font-size: 9px!important;
}
.font-10 {
  font-family: 'Archivo';
  font-size: 10px!important;
}
.font-11 {
  font-family: 'Archivo';
  font-size: 11px!important;
}
.font-12 {
  font-family: 'Archivo';
  font-size: 12px!important;
}
.font-13 {
  font-family: 'Archivo';
  font-size: 13px!important;
}
.font-14 {
  font-family: 'Archivo';
  font-size: 14px!important;
}
.font-15 {
  font-family: 'Archivo';
  font-size: 15px!important;
}
.font-16{
  font-family: 'Archivo';
  font-size: 16px!important;
}
.font-17{
  font-family: 'Archivo';
  font-size: 17px!important;
}
.font-18{
  font-family: 'Archivo';
  font-size: 18px!important;
}
.font-20{
  font-family: 'Archivo';
  font-size: 20px!important;
}
.font-21{
  font-family: 'Archivo';
  font-size: 21px!important;
}
.font-22{
  font-family: 'Archivo';
  font-size: 22px!important;
}
.font-23{
  font-family: 'Archivo';
  font-size: 23px!important;
}
.font-24{
  font-family: 'Archivo';
  font-size: 24px!important;
}
.font-25{
  font-family: 'Archivo';
  font-size: 25px!important;
}
.font-26{
  font-family: 'Archivo';
  font-size: 26px!important;
}
.font-27{
  font-family: 'Archivo';
  font-size: 27px!important;
}
.font-28{
  font-family: 'Archivo';
  font-size: 28px!important;
}
.font-29{
  font-family: 'Archivo';
  font-size: 29px!important;
}
.font-30{
  font-family: 'Archivo';
  font-size: 30px!important;
}
.font-31{
  font-family: 'Archivo';
  font-size: 31px!important;
}
.font-32{
  font-family: 'Archivo';
  font-size: 32px!important;
}
.font-33{
  font-family: 'Archivo';
  font-size: 33px!important;
}
.font-34{
  font-family: 'Archivo';
  font-size: 34px!important;
}
.font-35{
  font-family: 'Archivo';
  font-size: 35px!important;
}
.font-36{
  font-family: 'Archivo';
  font-size: 36px!important;
}
.font-37{
  font-family: 'Archivo';
  font-size: 37px!important;
}
.font-38{
  font-family: 'Archivo';
  font-size: 38px!important;
}
.font-39{
  font-family: 'Archivo';
  font-size: 39px!important;
}
.font-40{
  font-family: 'Archivo';
  font-size: 40px!important;
}

//RESPONSIVE POLICE
@media (max-width: 1000px){
  .font-40 {
    font-size: 30px!important;
  }
}

//---- COULEUR DE POLICE ----
.noir {
  color: black;
}
.blanc {
  color: #FFFFFF;
}
.bleu {
  color:#0042FF;
}
.rouge {
  color: #ff0000;
}
.gris-646464{
  color: #646464;
}
.gris-A2A2A2{
  color: #A2A2A2;
}
.bleu-00E1FF{
  color: #00E1FF;
}
.rouge-FF5C5C{
  color: #FF5C5C;
}

.font-15Cliquable {
  color: #0042FF;
  font-family: 'Archivo';
  font-size: 14px;
}

.labelInput {
  color: #000000;
  font-family: 'Archivo';
  font-size: 16px;
}

.restezCo {
  color: #646464;
  font-family: 'Archivo';
  font-size: 16px;
}